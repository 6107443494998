<template>
	<b-modal
		:title="`ASSET: ${imageDetails.assetCode}`"
		id="damaged-asset-image-view"
		:no-close-on-backdrop="true"
		size="lg"
		:hide-footer="true"
		centered
	>
		<b-row>
			<b-col sm="12">
				<div class="thumbnail text-right">
					<b-img
						v-img-orientation-changer
						:src="imageDetails.imgUrl"
						alt="Responsive image"
						class="img-responsive"
						fluid
					/>
					<div class="caption strokeme">
						<p>
							{{
								`lat: ${parseFloat(imageDetails.latitude).toFixed(
									6
								)}, lng: ${parseFloat(imageDetails.longitude).toFixed(6)}`
							}}
							<br />
							{{ dateCreated }}
						</p>
					</div>
				</div>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import EventBus from '@/shared/event-bus';

export default {
	name: 'DamagedAssetView',
	data() {
		return {
			imageDetails: {},
		};
	},
	computed: {
		dateCreated() {
			return DateUtil.getFormattedDateWithTime(this.imageDetails.dateCreated);
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelDamagedAssetImage', (imageDetails) => {
			this.imageDetails = imageDetails;
		});
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelDamagedAssetImage');
	},
};
</script>

<style scoped>
.thumbnail {
	position: relative;
}
.caption {
	position: absolute;
	bottom: 0px;
	left: -10px;
	width: 100%;
}
.strokeme {
	color: white;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
		1px 1px 0 #000;
}
</style>